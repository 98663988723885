import React from "react";
import "./feature.component.scss";
// import FeatureSvg from "../../svg/featureSVG.svg";
// import { Check } from "react-feather";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { FeaturesList } from "../../config/site.config";



export const FeatureComponent = () => {

  // const featureImageData = useStaticQuery(
  //   graphql`
  //     query FeatureImageData {
  //       file(childImageSharp: {}, relativePath: {eq: "feature.png"}) {
  //         childImageSharp {
  //           gatsbyImageData
  //         }
  //       }
  //     }
  //   `
  // );

  return (
    <>
      <div className="feature-container">
        <div className="feature-image-container">
          <div className="feature-image">
              
            <StaticImage
              className="feature-image transition-opacity"
              src="../../assets/images/feature.png"
              alt=""
              placeholder="tracedSVG"
            />
              
              {/* <div className="featureSVG"> */}
                {/* <FeatureSvg className="transition-opacity"/> */}
                {/* <GatsbyImage  image={featureImageData.file.childImageSharp.gatsbyImageData} alt={""} /> */}
              {/* </div> */}
              {/* <div className="featureSVGContainer"> */}
                {/* <FeatureSvg className="featureSVG transition-opacity"/> */}
              {/* </div> */}
          </div>
        </div>

        <div className="feature-content-container">
          <div className="feature-content">
            <ul>
              {FeaturesList.map((feature) => {
                return (
                  <li key={feature.id} className="item-feature">
                    {/* <Check className="icon" /> */}
                    <p key={"h-" + feature.id}>{feature.title}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
