import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./card.component.scss";

export const CardComponent = ({cardData}: {cardData: any}) => {
    
  return (
    <div className="card">
      <GatsbyImage className="card-image" image={cardData.img.childImageSharp.gatsbyImageData} alt={""} />
        
        <div className="card-container">
            <label className="card-type">{cardData.tipo}</label>
            <div className="card-title">
                <h2 className="title"><b>{cardData.title}</b></h2>
            </div>
            <div className="card-description">
                <p>{cardData.description}</p>
            </div>
        </div>
    </div>
  );
};
