import React from "react";
import { AboutOfContent } from "../../config/site.config";
// import HeroSvg from "../../svg/heroSVG.svg";
import "./about.of.component.scss";

export const AboutOfComponent = () => {
  return (
    <>
      <div className="about-of">
        <h1>{AboutOfContent.title}</h1>
        <br />
        <p>
          {AboutOfContent.content}
        </p>
      </div>
    </>
  );
};
