import { graphql, navigate, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import { SiteMetaDataInterface } from "../../interfaces/site.metadata.interface";
// import HeroSvg from "../../svg/heroSVG.svg";
import "./hero.component.scss";

export const HeroComponent = (siteMetaData: SiteMetaDataInterface) => {
  
  return (
    <>
      <div className="hero-container">
        <div className="hero-description-container">

          <div className="hero-description">
            <h1>
              Startup
              &nbsp;
              <strong> {siteMetaData.title}</strong>
            </h1>

            <p>
              {siteMetaData.description}
            </p>
            
            <div className="hero-buttons">
              <button className="button" onClick={()=>{navigate("/#contact")}}>Contacto</button>
              {/* <button className="button" onClick={()=>{navigate("/#contact  ")}}>Cotiza</button> */}
            </div>
          </div>
          </div>

        <div className="hero-image-container">
          {/* <img className="heroSVG transition-opacity" src={HeroSvg} alt="" /> */}
          <div className="hero-image">
            {/* <HeroSvg className="heroSVG transition-opacity"/> */}
            <StaticImage
              className="hero-image transition-opacity"
              src="../../assets/images/hero.png"
              alt=""
              placeholder="tracedSVG"
            />
          </div>
        </div>
      </div>
    </>
  );
};
