export const AboutOfContent = {
    title: "ACERCA DE DEVISINGGAMES",
    content: "Somos una STARTUP establecida en la ciudad de Quito - Ecuador, fundada con el objetivo de brindar soluciones digitales creativas e innovadoras que se ajusten a las necesidades de nuestros clientes y sus proyectos."
}

export const FeaturesList: {
    id: string;
    title: string;
    icon: string;
    description: string;
  }[] = [
    {
      id: "1",
      title: "Software a medida.",
      icon: "",
      description: "1",
    },
    {
      id: "2",
      title: "Diseño y planificación.",
      icon: "",
      description: "2",
    },
    {
      id: "3",
      title: "Desarrollo ágil.",
      icon: "",
      description: "3",
    },
    {
      id: "4",
      title: "Experiencia de usuario.",
      icon: "",
      description: "4",
    },
    {
      id: "5",
      title: "Evaluación y pruebas.",
      icon: "",
      description: "5",
    },
    {
      id: "6",
      title: "Despliegue continuo.",
      icon: "",
      description: "6",
    },
    {
      id: "7",
      title: "Y más.",
      icon: "",
      description: "6",
    },
];


export const ProjectsData: {
  id: string;
  title: string;
  tipo: string;
  description: string;
  imageName: string;
  imagePath: string;
  alt: string;
  url: string;
}[] = [
  {
    id: "project-1",
    title: "SITIO WEB TNS CONVENIOS",
    tipo: "Sitio Web",
    imageName: "1.png",
    description: "Proyecto T.N.S Agencia Asesora De Convenios, firma de contratos empresariales con empresas y aseguradoras en la ciudad de Quito. Como fase inicial del proyecto se desarrolló el modelo de negocios basado en CANVA, además del branding de la marca con diseños de logo, slogan, tipografía y colores. En las siguientes fases se realizó el desarrollo, las evaluaciones y despliegue del sitio web www.tnsconvenios.com. Además, como servicios adicionales se crearon las redes sociales enfocadas al marketing del negocio.",
    imagePath: "../../assets/images/hero.png",
    alt: "",
    url: "https://www.tnsconvenios.com"
  },
  {
    id: "project-2",
    title: "JUEGO SERIO GIRHA",
    tipo: "Escritorio",
    imageName: "2.png",
    description: "GIRHA (Gestión Integral del Recurso Hídrico y Ambiental) es un juego serio de escritorio creado con el objetivo de promover de una manera atractiva y divertida el uso adecuado de los recursos hídricos de la cuenca del Río San Pedro (ECUADOR), mediante la toma de decisiones, comunicación y trabajo en equipo, con un diseño amigable y fácil de usar. Proyecto desarrollado para equipos de escritorio con windows, pero con una arquitectura que permite su escalamiento a una Plataforma Web.",
    imagePath: "../../assets/images/2.png",
    alt: "",
    url: ""
  },
  {
    id: "project-3",
    title: "BLOG PERSONAL CRISWEB.ME",
    tipo: "Blog",
    imageName: "3.png",
    description: "Agencia digital, especializada en la creación e implementación de experiencias digitales, atractivas y a la medida. Un proyecto compuesta por una la página web corporativa, su propio blog personal, chatbot, SEO Optimizado, seguimiento del sitio y anuncios publicitarios de Google dentro de sítio. Además de su propio CMS para la gestión de los blogs, el sitio web cuenta con su propio dominio: www.crisweb.me. ",
    imagePath: "../../assets/images/3.png",
    alt: "",
    url: "https://www.crisweb.me"
  }
];

export const ContactData: {
  email: string;
  movil: string;
} = {
  email: 'devisinggames.info@gmail.com',
  movil: '+593-99-407-3356 / +593-98-719-2388'
}