import { graphql, useStaticQuery } from "gatsby";
// import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import { CardComponent } from "../../components/card/card.component";
// import { CardComponent } from "../card/card.component";
import "./projects.component.scss";


export const ProjectsComponent = () => {

  const projectsData = useStaticQuery(
    graphql`
      query ProjectsData {
        allProjectsJson {
          edges {
            node {
              alt
              description
              id
              img {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              title
              tipo
              url
            }
          }
        }
      }    
    `
  );

  const getAllProjectsData = (allProjectsData: any) => {
    const projectsArray: any[] = [];
    allProjectsData.allProjectsJson.edges.forEach(
      (projectData: any, index: any) => {
        projectsArray.push(
          projectData.node.url !== '' ? 
                <a className="project-card-container" key={projectData.node.id} href={projectData.node.url} target="_blank">
                  <CardComponent key={projectData.node.id} cardData={projectData.node}/>
                </a>
                :
                <CardComponent key={projectData.node.id} cardData={projectData.node}/>
                
        );
      }
    );
    return projectsArray;
  }

  return (
    <>
      <div className="projects">
          <br />
          <h1>PROYECTOS</h1>
          <br />
          <div className="projects-cards">
            {
              getAllProjectsData(projectsData)
            }
          </div>
          <br /> 
        </div>
    </>
  );
};
