import React from "react";
import { ContactData } from "../../config/site.config";
import "./contact.component.scss";
// import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Mail, Phone } from "react-feather";
// import { graphql, useStaticQuery } from "gatsby";

export const ContactComponent = () => {

  return (
    <>
        <div className="contact-container">
            <h1>INFORMACIÓN DE CONTACTO</h1>

            <div className="contact-items-container">
                
                <div className="item">
                    <Phone className="icons"/>
                    <h2>Teléfono (Whatsapp)</h2>
                    {ContactData.movil}
                </div>

                <div className="item">
                    <Mail className="icons"/>
                    <h2>Correo</h2>
                    {ContactData.email}
                </div>

                {/* <div className="item">
                    <OutboundLink href={projectsData.pdf.parent.publicURL} target="_blank"> 
                        <Download/>
                        <h2>Folleto de Servicios</h2>
                    </OutboundLink>
                </div> */}
            </div>
        </div>  
    </>
  );
};
