import * as React from "react";
// import { AboutOfComponent } from "../components/about-of/about.of.component";
// import { ContactComponent } from "../components/contact/contact.component";
// import { FeatureComponent } from "../components/feature/feature.component";
// import { HeroComponent } from "../components/hero/hero.component";
// import { ProjectsComponent } from "../components/projects/projects.component";
import "../scss/style.scss";
import { MainLayout } from "../layouts/main/main.layout";
import { graphql, useStaticQuery } from "gatsby";
import { AboutOfComponent } from "../seccions/about-of/about.of.component";
import { HeroComponent } from "../seccions/hero/hero.component";
import { FeatureComponent } from "../seccions/feature/feature.component";
import { ProjectsComponent } from "../seccions/projects/projects.component";
import { ContactComponent } from "../seccions/contact/contact.component";

const IndexPage = () => {

  // SiteMetaDataInterface

    const siteMetaData = useStaticQuery(
      graphql`
        query SiteMetaData {
          site {
            siteMetadata {
              author
              description
              image
              menuLinks {
                link
                name
              }
              siteUrl
              title
              twitterUsername
              url
              lang
              charSet
            }
          }
        }        
      `
    )


  return (
    <>
    
      <MainLayout className={"footer"} siteMetaData = {siteMetaData.site.siteMetadata}>
        <section id="home">
          <HeroComponent {...siteMetaData.site.siteMetadata}/>
        </section>
        <section id="about-of">
          <AboutOfComponent />
        </section>
        <section id="about-of">
          <FeatureComponent />
        </section>
        <section id="projects">
          <ProjectsComponent />
        </section>
        <section id="contact">
          <ContactComponent />
        </section>
      </MainLayout>
    </>
  )
}

export default IndexPage;
